import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import ErpDocumentEntity from "../../../document/entity";
import * as pdfjsLib from "pdfjs-dist"
import SignaturePad from "signature_pad";

export default class ErpOrderDocument{
    private parent: any;
    private entity = "erp/documents";
    private toastr: any;
    private datatableElem: any;
    private datatable: any;

    private pdfDocumentId = "";
    private pdfDownloadUrl = "";
    private pdfModal: bootstrap.Modal;
    private pdfSignModal: bootstrap.Modal;
    private signaturePad: SignaturePad | undefined;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.datatableElem = jQuery(".datatables-erp-order-document")
        this.pdfModal = new bootstrap.Modal((document.querySelector("#erpOrderDocumentPdfModal") as HTMLElement));
        this.pdfSignModal = new bootstrap.Modal((document.querySelector("#erpOrderDocumentPdfSignModal") as HTMLElement));
        this.setupPdfReader()
        this.bindListeners();
    }

    async setupPdfReader() {
        pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
    }

    async setupSigning() {
        const canvas = document.querySelector('#erpOrderDocumentPdfSignCanvas') as HTMLCanvasElement | null;
        if (canvas) {
            if (!this.signaturePad) {
                this.signaturePad = new SignaturePad(canvas);
            } else {
                this.signaturePad.clear();
            }
        }
    }

    async saveSignature() {
        if (this.signaturePad) {
            const image = this.signaturePad.toDataURL();
            await Utils.erp.saveSignature(this.pdfDocumentId, this.pdfDownloadUrl, image)
        }
    }

    async loadPdf(downloadUrl: string) {
        pdfjsLib.getDocument(`${document.location.origin}/${downloadUrl}`).promise.then((pdf) => {
            let pageNumber = pdf.numPages;
            pdf.getPage(pageNumber).then((page) => {
                const scale = 1.5;
                const viewport = page.getViewport({scale: scale});

                const canvas = document.querySelector('#erpOrderDocumentPdfCanvas') as HTMLCanvasElement | null;
                if (canvas){
                    const context = canvas.getContext('2d');
                    if (context) {
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
                        const renderTask = page.render(renderContext);
                        renderTask.promise.then( () => {
                            console.log('Page rendered');
                            this.pdfModal.show();
                            const button = document.querySelector('#erpOrderDocumentPdfModalSignButton') as HTMLButtonElement;

                            button.style.bottom  = `${window.siteConfig.document.y}px`
                            button.style.right = `${window.siteConfig.document.x}px`
                            button.style.width = window.siteConfig.document.width;
                            button.style.height = window.siteConfig.document.height;
                        });
                    }
                }
            });
        })
    }

    getEntityData(elem: any) {
        return {...ErpDocumentEntity.getEntityData(elem), orderId: this.parent.id}
    }

    bindListeners() {

        jQuery(".datatables-erp-order-document tbody").delegate(".sign-record", "click", async (e: JQuery.TriggeredEvent) => {
            this.pdfDownloadUrl = e.currentTarget.getAttribute("data-url");
            this.pdfDocumentId = e.currentTarget.getAttribute("data-document-id");
            await this.loadPdf(this.pdfDownloadUrl);

        });
        (document.querySelector('#erpOrderDocumentPdfModalSignButton') as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            await this.setupSigning();
            this.pdfSignModal.show();
        });

        (document.querySelector('#erpOrderDocumentPdfSignSave') as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            await this.saveSignature();
            this.pdfSignModal.hide();
            this.pdfModal.hide();
            await this.parent.getEntity();
        });

        (document.querySelector("#addErpOrderDocumentForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            const elem = document.querySelector("#addErpOrderDocumentForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                await Utils.showLoader();
                await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                this.toastr.success(`${Utils.translate('erp.document.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)


                const bsElem = bootstrap.Offcanvas.getInstance((document.querySelector("#offcanvasAddErpOrderDocument") as HTMLElement))
                if (bsElem) {
                    bsElem.hide();
                }
                await Utils.hideLoader();
                await this.parent.getEntity()
            }
        });

        jQuery("#erp_document_document_type_id").on("select2:select", (e: any) => {
            (document.querySelector("#erp_document_document_type_name") as HTMLInputElement).value = e.params.data.data.technicalName;
        })
    }


    createDataTable() {
        this.datatable = this.datatableElem.DataTable({
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-6 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords":    `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable":     `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first":      `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last":       `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next":       `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous":   `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info":           `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty":      `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered":   `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    attr: {
                        'data-bs-toggle': 'offcanvas',
                        'data-bs-target': '#offcanvasAddErpOrderDocument'
                    }
                }
            ]
        });
    }

    async update(data: any) {
        if (this.datatable) {
            this.datatable.destroy();
        }

        await Utils.updateElements(data, '', (document.querySelector("#erp_order_document") as HTMLElement))
        const table = document.querySelector(".datatables-erp-order-document tbody") as HTMLElement;
        table.innerHTML = "";
        data.documents.forEach((document: any) => {
            let tr = `<tr>
                <td>${new Date(Date.parse(document.createdAt)).toLocaleString()} ${document.signed ? `<b>(Signiert)</b>` : ``}</td>
                <td>${document.config.documentNumber}</td>
                <td>${Utils.translate(`erp.document.types.${document.config.name}`)}</td>
                <td><div class="d-flex align-items-center"> 
                <a href="/api/v1/erp/documents/download?documentId=${document.id}&permalinkId=${document.deepLinkCode}&name=${btoa(`${document.config.name}_${document.config.documentNumber}.pdf`)}" target="_blank" class="text-body download-record"> <i class="ti ti-download ti-sm me-2"></i> </a>`
                if (!document.signed) {
                    tr += `<a href="#" data-document-id="${document.id}" data-url="/api/v1/erp/documents/download?documentId=${document.id}&permalinkId=${document.deepLinkCode}&name=${btoa(`${document.config.name}_${document.config.documentNumber}.pdf`)}" class="text-body sign-record"> <i class="ti ti-writing-sign ti-sm me-2"></i> </a>`
                }
              tr += `</div></td></tr>`
            table.innerHTML = table.innerHTML + tr;
        });
        this.createDataTable();
    }
}